<template>
  <ContentWrapper :links="topLinks">
    <Table stripe :columns="columns" :data="mediaList" :loading="mediaLoading"></Table>
    <Pager :total="mediaListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryMedia, deleteMedia } from "@/api"
import { toHumanDate, imgHolder } from "@/util"

export default {
  name: "MediaQueryPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "媒体文件", link: this.$route.path }],
      page: 1,
      mediaLoading: false,
      mediaList: [],
      mediaListCount: 0,
      columns: [
        { title: "标识", key: "id", minWidth: 50, maxWidth: 100 },
        {
          title: "预览",
          width: 100,
          render: (h, params) => {
            return imgHolder(h, params.row.imageUrl)
          },
        },
        { title: "原文件名", key: "originalFilename", minWidth: 150 },
        { title: "宽度", key: "mediaWidth", minWidth: 50, maxWidth: 80 },
        { title: "高度", key: "mediaHeight", minWidth: 50, maxWidth: 80 },
        {
          title: "上传时间",
          key: "createdAt",
          render: (h, params) => h("div", toHumanDate(params.row.createdAt)),
        },
        {
          title: "操作",
          render: (h, params) => {
            return h("ButtonGroup", [
              h(
                "Button",
                {
                  props: { type: "warning" },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        content: "为了展示正确, 删除图片将导致关联的商品下架, 以避免错误, 确认删除吗?",
                        onOk: () => {
                          this.delete(params.row.id)
                        },
                      })
                    },
                  },
                },
                "删除"
              ),
            ])
          },
        },
      ],
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.mediaLoading = true
        const { items, count } = await queryMedia(this.page)
        this.mediaList = items
        this.mediaListCount = count
        this.mediaLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async delete(id) {
      try {
        await deleteMedia(id)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
